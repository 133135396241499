import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import useWindowWidth from "hooks/useWindowWidth";

const Stats = ({ padding = true, textLeft = false }) => {
  const width = useWindowWidth();
  return (
    <section className={cn(styles.root, padding ? styles.padding : "")}>
      <div className="container">
        <h2
          className={cn(
            styles.title,
            textLeft && width < 640 ? "align_left" : "align_center",
            "font_extra-bold"
          )}
        >
          Powered by data. Proved by numbers. Built for scale.
        </h2>
        <div className={cn(styles.content, "align_center")}>
          <div className={styles.col}>
            <label>150M+</label>
            <span>Events tracked monthly</span>
          </div>
          <div className={styles.col}>
            <label>$1B+</label>
            <span>Total revenue tracked</span>
          </div>
          <div className={styles.col}>
            <label>20M+</label>
            <span>End users monthly</span>
          </div>
          <div className={styles.col}>
            <label>99.99%</label>
            <span>Infrastructure uptime</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
