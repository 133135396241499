import React from "react";
import styles from "./index.module.scss";
import useWindowWidth from "hooks/useWindowWidth";

const Apps = ({ showTitle = true }) => {
  const width = useWindowWidth();
  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>
        {showTitle && <h2 className={styles.title}>Trusted by 7,500+ apps</h2>}
        <div className={styles.apps}>
          <div className={styles.list}>
            {[...Array(104)].map((_, i) => (
              <div className={styles.icon} key={i}>
                <img
                  src={`/icons/img${i + 1}.webp?q=${Math.random()}`}
                  alt=""
                  width={width > 640 ? "50" : "40"}
                  height={width > 640 ? "50" : "40"}
                />
              </div>
            ))}
          </div>
          <div className={styles.list}>
            {[...Array(104)].map((_, i) => (
              <div className={styles.icon} key={i}>
                <img
                  src={`/icons/img${i + 1}.webp`}
                  alt=""
                  width={width > 640 ? "50" : "40"}
                  height={width > 640 ? "50" : "40"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apps;
