import Head from "next/head";
import Jumbotron from "../components/Home/Jumbotron";
import TabOneSection from "../components/Home/TabSection";
import Gallery from "../components/Home/Gallery";
import Tab from "../components/Home/TabSection/Tab";
import Image from "next/image";
import React from "react";
import Stats from "../components/Home/Stats";
import InAppRevenue from "../components/Home/InAppRevenue";
import GrowYourApp from "../components/Home/GrowYourApp";
import Promo from "../components/Home/Promo";
import Button from "../components/UI/Button";
import ButtonArea from "../components/Home/ButtonArea";
import Link from "next/link";
import Predictions from "components/Home/Predictions";

export default function Home() {
  return (
    <div className="wrapper">
      <Head>
        <title>
          More than analytics for iOS and Android in-app subscriptions – Apphud
        </title>
        <link rel="canonical" href="https://apphud.com/" />
        <meta
          property="og:title"
          content="Mobile app analytics tool for iOS and Android in app subscriptions – Apphud"
        />
        <meta
          property="og:description"
          content="Apphud is a platform to build, measure and improve iOS and Android auto-renewable subscriptions. Start using mobile app analytics tool for free today."
        />
        <meta property="og:url" content="https://apphud.com" />
        <meta
          property="og:image"
          content="/images/og/im_1.webp"
          key="og:image"
        />
        <meta
          property="twitter:image"
          content="/images/og/im_1.webp"
          key="twitter:image"
        />
        <meta
          property="twitter:title"
          content="Mobile app analytics tool for iOS and Android in app subscriptions – Apphud"
        />
        <meta
          property="twitter:description"
          content="Apphud is a platform to build, measure and improve iOS and Android auto-renewable subscriptions. Start using mobile app analytics tool for free today."
        />
        <meta property="twitter:url" content="https://apphud.com" />
        <meta name="twitter:domain" content="apphud.com" />
        <meta
          name="description"
          content="Apphud is a platform to build, measure and improve iOS and Android auto-renewable subscriptions. Start using mobile app analytics tool for free today."
        />
      </Head>
      <Jumbotron />
      <TabOneSection
        tabs={[
          "Cohorts and funnels",
          "Dashboard and charts",
          "Advanced filters",
        ]}
        title={"Real-time app revenue analytics"}
        description={
          "Use robust visualizations and reports to see the big picture of the app economy down to the finest details."
        }
      >
        <Tab
          title="Cohorts and funnels"
          suffix={
            <Image
              src="/homeNew/analytics_01.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Measure paid users and revenue retention with 99.9% accuracy. Identify
          and fix bottlenecks in your app monetization funnel
        </Tab>
        <Tab
          title="Dashboard and charts"
          suffix={
            <Image
              src="/homeNew/analytics_02.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Get all-embracing visibility of your app MRR, ARPU, Churn, Refund
          rate, and 20+ more key revenue metrics to make data-driven decisions
        </Tab>
        <Tab
          title="Advanced filters"
          suffix={
            <Image
              src="/homeNew/analytics_03.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Get granular insights and discover your hidden revenue drivers with
          data segmentation by user acquisition channel, age, location, and 20+
          more properties
        </Tab>
      </TabOneSection>
      <TabOneSection
        type="second"
        tabs={["20+ integrations", "Attribution", "Web-to-web solution"]}
        title={"Cutting-edge mobile marketing solution"}
        description={
          "Seamlessly measure your ad performance and elevate your marketing strategy by relying on accurate, purified revenue data."
        }
      >
        <Tab
          title="20+ integrations"
          prefix={
            <Image
              src="/homeNew/solutions_1_new.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Easily connect Apphud with MMPs, BI, and other tools you use. Send
          in-app subscription conversions, renewals, cancellations, and refunds
          to integrations and vastly simplify ad performance measurement
        </Tab>
        <Tab
          title="Attribution"
          prefix={
            <Image
              src="/homeNew/solution_02.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Scale your Apple Search Ads (ASA) by segmenting revenue metrics to
          gain insights. Reduce Web-to-App campaign costs by reporting
          conversion events to Facebook and TikTok.
        </Tab>
          <Tab
              title="Web-to-web solution"
              prefix={
                  <Image
                      src="/homeNew/solution_03.svg"
                      width="488"
                      height="368"
                      loading="lazy"
                  />
              }
          >
              <div>
                  <p>

              Easily create custom workflows with Apphud Flow without any coding
              skills. Launch high-converting web-to-web campaigns in days. Analyze
              and optimize your results with detailed, granular data.
                  </p>

              <a href="/flows" target="_blank" rel="noopener noreferrer">
                  <Button color="blue" title="Learn more"/>
              </a>
              </div>

          </Tab>
      </TabOneSection>
        <TabOneSection
            tabs={["A/B/C pricing tests", "Push notifications", "Win back refunds"]}
            title={"Powerful app growth platform"}
        description={
          "Apphud is an essential tool to grow app lifetime value from day one. Supercharged with tools to find the best pricing, radically reduce churn, and recover lapsed subscribers."
        }
      >
        <Tab
          title="A/B/C pricing tests"
          suffix={
            <Image
              src="/homeNew/growth_01.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Find the best performing combination of prices and purchase screens
          that maximizes your app profit
        </Tab>
        <Tab
          title="Push notifications"
          suffix={
            <Image
              src="/homeNew/growth_02.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Retain and return customers with special discounts through automated Rules. Create offer screens and surveys without coding skills.
        </Tab>
          <Tab
              title="Win back refunds"
              suffix={
                  <Image
                      src="/homeNew/growth_03.svg"
                      width="488"
                      height="368"
                      loading="lazy"
                  />
              }
          >
              <div>
                  <p>
                      Effectively manage refund requests on the App Store with automated processes to minimize revenue loss.
                  </p>
              <a href="/win-back-refunds" target="_blank" rel="noopener noreferrer">
                  <Button color="blue" title="Learn more"/>
              </a>
              </div>
          </Tab>
      </TabOneSection>
        <TabOneSection
            type="second"
            tabs={["SDK for any platform", "Observer mode", "Revenue API"]}
        title={"Reliable in-app subscriptions infrastructure"}
        description={
          "Apphud takes care of in-app subscriptions implementation nightmare, so you can dramatically reduce dev costs and focus on the product."
        }
      >
        <Tab
          title="SDK for any platform"
          prefix={
            <Image
              src="/homeNew/infra_01.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Lightweight open-source Apphud SDKs are designed to work seamlessly on
          both iOS and Android and support cross-platform apps
        </Tab>
        <Tab
          title="Observer mode"
          prefix={
            <Image
              src="/homeNew/infra_02.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Use Apphud along with the existing solution to reduce release cycles,
          and to benefit from precise revenue tracking we provide
        </Tab>
        <Tab
          title="Revenue API"
          prefix={
            <Image
              src="/homeNew/infra_03.svg"
              width="488"
              height="368"
              loading="lazy"
            />
          }
        >
          Enrich your BI data with the accurate API that provides App Store and
          Google Play tax and currency exchange rates, and proper proceeds
          calculation
        </Tab>
      </TabOneSection>
      <ButtonArea>
        <a href="/demo" target="_blank" rel="noopener noreferrer">
          <Button title="Book a demo" />
        </a>
      </ButtonArea>
      <Predictions />
      <InAppRevenue />
      <ButtonArea>
        <Link href={"/blog/apphud-data-accuracy-research"}>
          <Button title="Read the research" />
        </Link>
      </ButtonArea>
      <GrowYourApp />
      <ButtonArea>
        <a href="/demo" target="_blank" rel="noopener noreferrer">
          <Button title="Book a demo" />
        </a>
      </ButtonArea>
      <Stats />
      <Gallery />
      <Promo />
    </div>
  );
}
